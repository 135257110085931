<template>
    <div class="container-fluid">
        <div class="container">
            <h4>Ваш заказ успешно оформлен.</h4>
            <p class="product-order">
                Управлять заказом и отслеживать его статус<br> Вы можете
                <router-link v-if="isCurrentUserDealer" class="link" :to="{name: 'VisitOrders'}"> в личном кабинете.
                </router-link>
                <router-link v-if="isCurrentUserStructuralSubdivisionUser" class="link"
                             :to="{name: 'VisitOrders'}"> в личном кабинете.
                </router-link>
            </p>
            <div class="img_"><img src="../../assets/images/box.png"></div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "SuccessfulOrder",
    async mounted() {
        await this.getUserInfo();
    },
    methods: {
        ...mapActions('account', ['getUserInfo'])
    },
    metaInfo() {
        return {
            title: `Ваш заказ успешно оформлен - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>
h4 {
    text-transform: uppercase;
    padding-top: 95px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.product-order {
    text-align: center;
    padding-top: 15px;
    font-size: 15px;

}

.link {
    color: #000000;
}

.link:hover {
    font-weight: bold;
}

.img_ {
    text-align: center;
}
</style>
