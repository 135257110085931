import { render, staticRenderFns } from "./SuccessfulNewVisitOrder.vue?vue&type=template&id=99d484ea&scoped=true&"
import script from "./SuccessfulNewVisitOrder.vue?vue&type=script&lang=js&"
export * from "./SuccessfulNewVisitOrder.vue?vue&type=script&lang=js&"
import style0 from "./SuccessfulNewVisitOrder.vue?vue&type=style&index=0&id=99d484ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99d484ea",
  null
  
)

export default component.exports